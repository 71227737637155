<template>
  <div>
    <b-tabs @input="selectVariation">
      <b-tab
        v-for="variation in variations"
        :key="variation.id"
        :title="`Supplier: ${variation.supplier.booking_name}`"
        class="pt-3"
      >
        <h3>Kontaktinformasjon</h3>
        <p>
          Kontaktinformasjon for <strong>{{ variation.supplier.booking_name }}</strong> i {{ variation.place.name }}
          <b-badge
            :variant="
              variation.status === 1
                ? 'primary'
                : variation.status === 2
                ? 'secondary'
                : variation.status === 0
                ? 'danger'
                : ''
            "
          >
            {{
              variation.status === 1
                ? 'Active'
                : variation.status === 2
                ? 'Bookable'
                : variation.status === 0
                ? 'Disabled'
                : ''
            }}
          </b-badge>
          <b-badge
            variant="primary"
            class="ml-1"
          >
            {{
              variation.booking_type === 'standard'
                ? 'Standard'
                : variation.booking_type === 'csv'
                ? 'CSV'
                : variation.booking_type === 'date'
                ? 'Date'
                : ''
            }}
          </b-badge>
        </p>

        <div v-if="variation.booking_type === 'standard' && variation.standard_booking_info">
          <h5>Viktig informasjon</h5>
          <p v-html="variation.standard_booking_info"></p>
        </div>

        <div v-if="variation.booking_type === 'date' && variation.booking_info">
          <h5>Viktig informasjon</h5>
          <p v-html="variation.booking_info"></p>
        </div>

        <div v-if="variation.booking_type === 'csv' && variation.booking_csv_info">
          <h5>Viktig informasjon</h5>
          <p v-html="variation.booking_csv_info"></p>
        </div>

        <b-table :items="supplierContactInfo(variation)">
          <template v-slot:cell(name)="{ item }">
            <strong>{{ item.name }}:</strong>
          </template>
        </b-table>
        <b-button
          v-if="Object.keys(variation).length && ! redeemed_at"
          variant="default"
          class="mr-2"
          v-b-modal="`giftcard-${giftcard.id}`"
        >
          Redeem Giftcard
        </b-button>

        <b-button @click="copyContactInformation">
          Copy contact information
        </b-button>
      </b-tab>
      <b-tab title="Transactions" class="pt-3" lazy>
        <giftcard-history :giftcard="giftcard" />
      </b-tab>
    </b-tabs>

    <hr />

    <b-row align-h="end">
      <b-col cols="auto">
        <b-modal title="Redeem Giftcard" :id="`giftcard-${giftcard.id}`" hide-footer :show="loading">
          <b-overlay>
              <div v-for="(selectedGiftcard, index) in getData" :key="selectedGiftcard.id" class="custom-control custom-checkbox">
                  <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`checkbox-${selectedGiftcard.id}`"
                      size="lg"
                      :checked="selectedGiftcard?.checked"
                      @change="updateSelectedGiftCards(index)"
                  />
                  <label class="custom-control-label" :for="`checkbox-${selectedGiftcard.id}`">
                      {{ selectedGiftcard.code }} - {{ selectedGiftcard?.amount }}
                  </label>
              </div>

            <form @submit.prevent="redeemCode">
              <b-row align-v="end">
                <b-col>
                  <b-form-group label="Experienced at">
                    <b-form-datepicker v-model="experienced_at" :min="new Date()" />
                  </b-form-group>
                </b-col>
                <b-col cols="auto">
                  <b-button variant="primary" style="margin-bottom: 1rem" type="submit"> Redeem </b-button>
                </b-col>
              </b-row>
            </form>
          </b-overlay>
        </b-modal>

        <b-button variant="primary" @click.prevent="$emit('ok')">Ok</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { products, giftcard } from '@/api'
import GiftcardHistory from './GiftcardHistory.vue'

export default {
  name: 'OrderDetailInfo',
  components: { GiftcardHistory },
  props: {
      giftcard: {
          type: Object
      },
      old_product_info_id: {
          default: null
      },
      product_id: {
          default: null
      } ,
      filteredOrderDetails: {
          type: Array
      }
  },
  data() {
    return {
        variations: [],
        selectedVariation: {},
        loading: true,
        experienced_at: '',
        redeemed_at: this.giftcard.redeemed_at,
        selectedGiftCards: []
    }
  },
  beforeMount() {
    this.loadVariation()
  },
  computed: {
    getData() {
        this.filteredOrderDetails.forEach((filteredOrderDetail) => {
            if (! filteredOrderDetail.giftcard?.redeemed_at && filteredOrderDetail.code) {
                this.selectedGiftCards.push({
                    id: filteredOrderDetail.giftcard?.id,
                    code: filteredOrderDetail.giftcard?.code,
                    name: filteredOrderDetail.product.name,
                    redeemed_at: filteredOrderDetail.giftcard?.redeemed_at,
                    checked: filteredOrderDetail.giftcard.code === this.giftcard.code,
                    amount: `${filteredOrderDetail?.price?.amount/100} NOK`
                })
            }}
        )

        return this.selectedGiftCards
    },
},
  methods: {
    supplierContactInfo(supplier) {
      const data = []

      if (supplier.booking_phone) {
        data.push({
          name: 'Telefonnummer',
          value: supplier.booking_phone,
        })
      }

      if (supplier.booking_phone_time) {
        data.push({
          name: 'Telefontid',
          value: supplier.booking_phone_time,
        })
      }

      if (supplier.booking_email) {
        data.push({
          name: 'E-postadresse',
          value: supplier.booking_email,
        })
      }

      if (supplier.booking_address) {
        const bookingDetails = `, ${supplier.booking_city || ''} ${supplier.booking_postal_code || ''}`

        data.push({
          name: 'Besøksadresse',
          value: `${supplier.booking_address}${bookingDetails.length > 3 ? bookingDetails : ''}`,
        })
      }

      if (supplier.booking_website_url) {
        data.push({
          name: 'Hjemmeside',
          value: supplier.booking_website_url,
        })
      }

      if (supplier.booking_url) {
        data.push({
          name: 'Bookingside',
          value: supplier.booking_url,
        })
      }

      return data
    },
    updateSelectedGiftCards(index) {
        this.selectedGiftCards[index].checked = !this.selectedGiftCards[index].checked
    },
    selectVariation(index) {
      this.selectedVariation = this.variations[index]
    },
    async loadVariation() {
      if (this.old_product_info_id) {
        const variations = await products.variations.all(this.product_id, {
          filter: {
            old_product_info_id: this.old_product_info_id,
          },
        })
        this.variations = variations.data()
        this.selectedVariation = this.variations[0]
      } else {
        const variation = await products.variations.find(this.giftcard.product_variation_id)

        this.variations = [variation.data]
        this.selectedVariation = this.variations[0]
      }
    },
    async redeemCode() {
      try {
        this.loading = true
          for (const selectedGiftCard of this.selectedGiftCards) {
              if (selectedGiftCard.checked) {
                  await giftcard.redeem(
                      selectedGiftCard.id,
                      this.experienced_at,
                      this.selectedVariation.supplier_id,
                      this.selectedVariation.id
                  )
                  this.$emit('triggerLoadOrderDetails')
                  this.$bvToast.toast(`Giftcard with code ${this.giftcard.code} was successfully redeemed.`, {
                      title: 'Giftcard Redeemed',
                      variant: 'primary',
                  })
              }
          }
      } catch (error) {
        this.$bvToast.toast(`Giftcard with code ${this.giftcard.code} could not be redeemed`, {
          title: 'Invalid Giftcard',
          variant: 'danger',
        })
      }
      this.$bvModal.hide(`giftcard-${this.giftcard.id}`)
      this.redeemed_at = true
    },
    copyContactInformation() {
        const textToCopy = `Kontaktinformasjon
Kontaktinformasjon for ${this.selectedVariation.supplier.booking_name} i ${this.selectedVariation.place.name}
Viktig informasjon
${this.supplierContactInfo(this.selectedVariation).map(i => `${i.name}: ${i.value}`).join('\n')}
`
      navigator?.clipboard?.writeText(textToCopy).then(() =>
          this.$bvToast.toast(`Contact information has been copied to clipboard.`, {
            title: 'Contact information copied',
            variant: 'primary',
          })
      )
    },
  },
}
</script>
