var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('display-results',{ref:"orderSearch",attrs:{"index-name":_vm.ordersIndex,"facetList":[
      { text: 'Voucher Code', value: 'voucher_code' },
      { text: 'Order ID', value: 'id' },
      { text: 'Giftcard ID', value: 'giftcard_id' },
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Company Name', value: 'company_name' },
      { text: 'Company Organization Number', value: 'company_organization_number' },
      { text: 'Email', value: 'email' },
      { text: 'Address', value: 'address' },
      { text: 'Phone', value: 'phone' },
      { text: 'Product Name', value: 'product_name' },
      { text: 'Voucher Code With Transaction History', value: 'voucher_code_with_history' },
    ]},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c('b-table',{ref:"orderstable",attrs:{"fields":_vm.fields,"items":_vm.mapItems(items),"show-empty":"","stacked":"sm","bordered":"","hover":""},on:{"row-clicked":row => _vm.toggleDetails(row)},scopedSlots:_vm._u([{key:"cell(id)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.id)+" "),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","size":"sm","title":"Copy ID"},on:{"click":function($event){return _vm.copyText(row.item.id)}}},[_c('i',{staticClass:"fa fa-copy"})])]}},{key:"cell(name)",fn:function(row){return [_vm._v(" "+_vm._s(`${row.item.first_name} ${row.item.last_name}`)+" ")]}},{key:"cell(delivery_method)",fn:function(row){return [_vm._v(" "+_vm._s(`${row.item.delivery_method}`)+" "),(row.item.delivery_method === 'post' && row.item.paid)?_c('b-button',{attrs:{"variant":row.item.delivered_at === null ? 'primary' : 'info',"size":"sm"}},[(row.item.delivered_at === null)?_c('span',{on:{"click":function($event){return _vm.markedPacked(row.item)}}},[_vm._v(" Mark as Packed ")]):_c('span',[_vm._v(" Packed ")])]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"target":"_blank","size":"sm","href":_vm.printLocation([row.item])}},[_vm._v("Print Voucher")])]}},{key:"cell(paid)",fn:function(row){return [_c('b-badge',{attrs:{"variant":row.item.paid ? 'primary' : 'warning'}},[_vm._v(" "+_vm._s(row.item.paid ? _vm.paidLabel(row.item) : 'Not Paid')+" "),(row.item.company_name)?_c('span',[_vm._v(" (B2B) ")]):_vm._e()])]}},{key:"cell(full_sum)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatMoney(row.item.full_sum))+" ")]}},{key:"row-details",fn:function({ item }){return [_c('b-overlay',{attrs:{"show":_vm.loading === item.id}},[_c('order-details',{attrs:{"order":item},on:{"loaded":function($event){_vm.loading = 0},"triggerUpdate":_vm.triggerSearch}})],1)]}}],null,true)})]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"page-header"},[_vm._v("Orders "),_c('small',[_vm._v("all listings.")])])
}]

export { render, staticRenderFns }